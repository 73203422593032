/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestNewCodebaseRequest
 */
export interface RequestNewCodebaseRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestNewCodebaseRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RequestNewCodebaseRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RequestNewCodebaseRequest
     */
    url: string;
}

export function RequestNewCodebaseRequestFromJSON(json: any): RequestNewCodebaseRequest {
    return RequestNewCodebaseRequestFromJSONTyped(json, false);
}

export function RequestNewCodebaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestNewCodebaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'url': json['url'],
    };
}

export function RequestNewCodebaseRequestToJSON(value?: RequestNewCodebaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'url': value.url,
    };
}

