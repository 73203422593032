/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Codebase,
    CodebaseFromJSON,
    CodebaseFromJSONTyped,
    CodebaseToJSON,
} from './Codebase';
import {
    Project,
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';
import {
    ProjectSnippetVersion,
    ProjectSnippetVersionFromJSON,
    ProjectSnippetVersionFromJSONTyped,
    ProjectSnippetVersionToJSON,
} from './ProjectSnippetVersion';
import {
    ProjectVersion,
    ProjectVersionFromJSON,
    ProjectVersionFromJSONTyped,
    ProjectVersionToJSON,
} from './ProjectVersion';

/**
 * 
 * @export
 * @interface FunctionWithCode
 */
export interface FunctionWithCode {
    /**
     * 
     * @type {string}
     * @memberof FunctionWithCode
     */
    code: string;
    /**
     * 
     * @type {Project}
     * @memberof FunctionWithCode
     */
    project: Project;
    /**
     * 
     * @type {Codebase}
     * @memberof FunctionWithCode
     */
    codebase: Codebase;
    /**
     * 
     * @type {ProjectSnippetVersion}
     * @memberof FunctionWithCode
     */
    snippet: ProjectSnippetVersion;
    /**
     * 
     * @type {ProjectVersion}
     * @memberof FunctionWithCode
     */
    version: ProjectVersion;
}

export function FunctionWithCodeFromJSON(json: any): FunctionWithCode {
    return FunctionWithCodeFromJSONTyped(json, false);
}

export function FunctionWithCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionWithCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'project': ProjectFromJSON(json['project']),
        'codebase': CodebaseFromJSON(json['codebase']),
        'snippet': ProjectSnippetVersionFromJSON(json['snippet']),
        'version': ProjectVersionFromJSON(json['version']),
    };
}

export function FunctionWithCodeToJSON(value?: FunctionWithCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'project': ProjectToJSON(value.project),
        'codebase': CodebaseToJSON(value.codebase),
        'snippet': ProjectSnippetVersionToJSON(value.snippet),
        'version': ProjectVersionToJSON(value.version),
    };
}

