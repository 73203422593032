/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LastDocumentationGenerationStatus,
    LastDocumentationGenerationStatusFromJSON,
    LastDocumentationGenerationStatusFromJSONTyped,
    LastDocumentationGenerationStatusToJSON,
} from './LastDocumentationGenerationStatus';

/**
 * 
 * @export
 * @interface ProjectOverview
 */
export interface ProjectOverview {
    /**
     * 
     * @type {number}
     * @memberof ProjectOverview
     */
    generationsLast7Days?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectOverview
     */
    codesearchQueriesLast7Days?: number;
    /**
     * 
     * @type {LastDocumentationGenerationStatus}
     * @memberof ProjectOverview
     */
    lastDocumentationStatus?: LastDocumentationGenerationStatus;
}

export function ProjectOverviewFromJSON(json: any): ProjectOverview {
    return ProjectOverviewFromJSONTyped(json, false);
}

export function ProjectOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'generationsLast7Days': !exists(json, 'generationsLast7Days') ? undefined : json['generationsLast7Days'],
        'codesearchQueriesLast7Days': !exists(json, 'codesearchQueriesLast7Days') ? undefined : json['codesearchQueriesLast7Days'],
        'lastDocumentationStatus': !exists(json, 'lastDocumentationStatus') ? undefined : LastDocumentationGenerationStatusFromJSON(json['lastDocumentationStatus']),
    };
}

export function ProjectOverviewToJSON(value?: ProjectOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'generationsLast7Days': value.generationsLast7Days,
        'codesearchQueriesLast7Days': value.codesearchQueriesLast7Days,
        'lastDocumentationStatus': LastDocumentationGenerationStatusToJSON(value.lastDocumentationStatus),
    };
}

