import { useEffect, useState } from "react";
import { useProjectApi } from "../api";
import { LoadingScreen } from "../components/Loading";
import { Project } from "../gen/backend";

export function Libraries() {
    const projectApi = useProjectApi();
    const [projects, setProjects] = useState<Project[]>([])
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    useEffect(() => {
        setIsLoading(true)
        projectApi.searchProjects({})
            .then(tmpProjects => {
                setIsLoading(false)
                setProjects(tmpProjects)
            })
    }, []);
    
    return isLoading ? <LoadingScreen /> :
    <div className="grid place-items-center w-full h-full">
        <div className="flex flex-wrap max-w-xl justify-center align-middle">
            {projects.map(p => <a key={p.id} className="ml-3 mt-3 text-lg tracking-wider font-medium text-sky-600 dark:text-sky-400 bg-sky-400/10 rounded-full py-1 px-6 xl:flex items-center hover:bg-sky-400/20 cursor-pointer" href={`/?lib=${p.title}`}><span className="capitalize">{p.title}</span></a>)}
        </div>
    </div>;
}