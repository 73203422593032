/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectSnippetVersionOwner,
    ProjectSnippetVersionOwnerFromJSON,
    ProjectSnippetVersionOwnerFromJSONTyped,
    ProjectSnippetVersionOwnerToJSON,
} from './ProjectSnippetVersionOwner';

/**
 * 
 * @export
 * @interface ProjectSnippetVersion
 */
export interface ProjectSnippetVersion {
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    snippetId: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    projectVersionId: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSnippetVersion
     */
    filePath: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    startLine: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    startColumn: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    endLine: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSnippetVersion
     */
    endColumn: number;
    /**
     * 
     * @type {ProjectSnippetVersionOwner}
     * @memberof ProjectSnippetVersion
     */
    owner?: ProjectSnippetVersionOwner;
    /**
     * 
     * @type {string}
     * @memberof ProjectSnippetVersion
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSnippetVersion
     */
    summary: string;
}

export function ProjectSnippetVersionFromJSON(json: any): ProjectSnippetVersion {
    return ProjectSnippetVersionFromJSONTyped(json, false);
}

export function ProjectSnippetVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSnippetVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'snippetId': json['snippetId'],
        'projectVersionId': json['projectVersionId'],
        'filePath': json['filePath'],
        'startLine': json['startLine'],
        'startColumn': json['startColumn'],
        'endLine': json['endLine'],
        'endColumn': json['endColumn'],
        'owner': !exists(json, 'owner') ? undefined : ProjectSnippetVersionOwnerFromJSON(json['owner']),
        'title': json['title'],
        'summary': json['summary'],
    };
}

export function ProjectSnippetVersionToJSON(value?: ProjectSnippetVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'snippetId': value.snippetId,
        'projectVersionId': value.projectVersionId,
        'filePath': value.filePath,
        'startLine': value.startLine,
        'startColumn': value.startColumn,
        'endLine': value.endLine,
        'endColumn': value.endColumn,
        'owner': ProjectSnippetVersionOwnerToJSON(value.owner),
        'title': value.title,
        'summary': value.summary,
    };
}

