/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LastDocumentationGenerationStatus
 */
export interface LastDocumentationGenerationStatus {
    /**
     * 
     * @type {string}
     * @memberof LastDocumentationGenerationStatus
     */
    status: LastDocumentationGenerationStatusStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof LastDocumentationGenerationStatus
     */
    endTime: Date;
}


/**
 * @export
 */
export const LastDocumentationGenerationStatusStatusEnum = {
    Error: 'error',
    Complete: 'complete',
    InProgress: 'in_progress'
} as const;
export type LastDocumentationGenerationStatusStatusEnum = typeof LastDocumentationGenerationStatusStatusEnum[keyof typeof LastDocumentationGenerationStatusStatusEnum];


export function LastDocumentationGenerationStatusFromJSON(json: any): LastDocumentationGenerationStatus {
    return LastDocumentationGenerationStatusFromJSONTyped(json, false);
}

export function LastDocumentationGenerationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): LastDocumentationGenerationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'endTime': (new Date(json['endTime'])),
    };
}

export function LastDocumentationGenerationStatusToJSON(value?: LastDocumentationGenerationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'endTime': (value.endTime.toISOString()),
    };
}

