/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CodebaseSearchMatch,
    CodebaseSearchMatchFromJSON,
    CodebaseSearchMatchToJSON,
    FrequentSearchItem,
    FrequentSearchItemFromJSON,
    FrequentSearchItemToJSON,
    GetSearchHistory200Response,
    GetSearchHistory200ResponseFromJSON,
    GetSearchHistory200ResponseToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
} from '../models';

export interface GetCodesearchProjectsRequest {
    offset?: number;
    limit?: number;
}

export interface GetFrequentQueriesRequest {
    projectId?: number;
}

export interface GetSearchHistoryRequest {
    projectId: number;
    page?: number;
    itemsPerPage?: number;
}

export interface SearchFunctionsRequest {
    q: string;
    projectId?: number;
}

/**
 * 
 */
export class CodesearchApi extends runtime.BaseAPI {

    /**
     * Returns a list of projects.
     */
    async getCodesearchProjectsRaw(requestParameters: GetCodesearchProjectsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codesearch/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * Returns a list of projects.
     */
    async getCodesearchProjects(requestParameters: GetCodesearchProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Project>> {
        const response = await this.getCodesearchProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the most frequent search queries
     */
    async getFrequentQueriesRaw(requestParameters: GetFrequentQueriesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<FrequentSearchItem>>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codesearch/frequent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FrequentSearchItemFromJSON));
    }

    /**
     * Get the most frequent search queries
     */
    async getFrequentQueries(requestParameters: GetFrequentQueriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<FrequentSearchItem>> {
        const response = await this.getFrequentQueriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get search history for the project
     */
    async getSearchHistoryRaw(requestParameters: GetSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GetSearchHistory200Response>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getSearchHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codesearch/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSearchHistory200ResponseFromJSON(jsonValue));
    }

    /**
     * Get search history for the project
     */
    async getSearchHistory(requestParameters: GetSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetSearchHistory200Response> {
        const response = await this.getSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A method for querying functions for the best match
     */
    async searchFunctionsRaw(requestParameters: SearchFunctionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<CodebaseSearchMatch>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling searchFunctions.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/codesearch/function`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CodebaseSearchMatchFromJSON));
    }

    /**
     * A method for querying functions for the best match
     */
    async searchFunctions(requestParameters: SearchFunctionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<CodebaseSearchMatch>> {
        const response = await this.searchFunctionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
