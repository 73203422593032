import {ReactComponent as TipsLogo} from '../assets/img/tips.svg'

export function How(){
    return <div className="grid place-items-center">
        <div className="bg-gray-200 dark:bg-gray-800 rounded-lg max-w-lg p-8 mt-8 text-center">
            <h1 className="text-3xl border-b-2 border-b-gray-300 dark:border-b-gray-700 pb-3 mb-5 flex justify-center">
                <TipsLogo className="h-auto w-6 mr-2 stroke-black dark:stroke-slate-400 fill-black dark:fill-slate-400" /> 
                How to</h1>
            <section className='mt-10'>
                <h2 className="text-2xl border-b border-b-gray-300 dark:border-b-gray-700 pb-3 mb-5">About Documatic</h2>
                <p>
                A search engine for your codebase. Ask Documatic and get code, insights, and analysis in response. Solve your problems and get back to coding quicker than ever.
                </p>
            </section>
            <section className='mt-10'>
                <h2 className="text-2xl border-b border-b-gray-300 dark:border-b-gray-700 pb-3 mb-5">About AskYourCode</h2>
                <p>
                A search engine for your codebase. Ask Documatic and get code, insights, and analysis in response. Solve your problems and get back to coding quicker than ever.
                </p>
            </section>
        </div>
    </div>;
}