/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrequentSearchItem
 */
export interface FrequentSearchItem {
    /**
     * 
     * @type {string}
     * @memberof FrequentSearchItem
     */
    query: string;
    /**
     * 
     * @type {Date}
     * @memberof FrequentSearchItem
     */
    firstAsked: Date;
    /**
     * 
     * @type {Date}
     * @memberof FrequentSearchItem
     */
    lastAsked: Date;
    /**
     * 
     * @type {number}
     * @memberof FrequentSearchItem
     */
    qcount: number;
    /**
     * 
     * @type {number}
     * @memberof FrequentSearchItem
     */
    projectId: number;
}

export function FrequentSearchItemFromJSON(json: any): FrequentSearchItem {
    return FrequentSearchItemFromJSONTyped(json, false);
}

export function FrequentSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrequentSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': json['query'],
        'firstAsked': (new Date(json['firstAsked'])),
        'lastAsked': (new Date(json['lastAsked'])),
        'qcount': json['qcount'],
        'projectId': json['projectId'],
    };
}

export function FrequentSearchItemToJSON(value?: FrequentSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'firstAsked': (value.firstAsked.toISOString()),
        'lastAsked': (value.lastAsked.toISOString()),
        'qcount': value.qcount,
        'projectId': value.projectId,
    };
}

