/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchHistoryItem
 */
export interface SearchHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof SearchHistoryItem
     */
    query: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchHistoryItem
     */
    created: Date;
}

export function SearchHistoryItemFromJSON(json: any): SearchHistoryItem {
    return SearchHistoryItemFromJSONTyped(json, false);
}

export function SearchHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': json['query'],
        'created': (new Date(json['created'])),
    };
}

export function SearchHistoryItemToJSON(value?: SearchHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'created': (value.created.toISOString()),
    };
}

