/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Codebase,
    CodebaseFromJSON,
    CodebaseToJSON,
} from '../models';

export interface GetCodebaseRequest {
    projectId: number;
}

/**
 * 
 */
export class CodebaseApi extends runtime.BaseAPI {

    /**
     * Fetches projects codebases details.
     */
    async getCodebaseRaw(requestParameters: GetCodebaseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Codebase>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getCodebase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/codebase`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodebaseFromJSON(jsonValue));
    }

    /**
     * Fetches projects codebases details.
     */
    async getCodebase(requestParameters: GetCodebaseRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Codebase> {
        const response = await this.getCodebaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
