import { useState } from "react"
import { useProjectApi } from "../api";
import {ReactComponent as DoneIcon} from "../assets/img/done.svg" 
import {ReactComponent as CloseIcon} from "../assets/img/close.svg" 
import { LoadingScreen } from "../components/Loading";

export function NewCodebase() {
    const [title, setTitle] = useState<string>("");
    const [repoURL, setRepoURL] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const projectApi = useProjectApi();
    const [submitted, setSubmitted] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const requestNew = () => {
        setIsLoading(true);
        projectApi.requestNewCodebase({requestNewCodebaseRequest: {
            name: title,
            url: repoURL,
            email: email
        }})
        .then(() => setSubmitted(true))
        .catch(() => setSubmitted(false))
        .finally(() => setIsLoading(false))
    }

    return <div className="w-full lg:max-w-lg text-center m-auto">
        <div>
            <h1 className="text-xl mb-2">If you want to index a <span>private</span> codebase,</h1>
            <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg m-auto">
                <div className="bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200" id="modal-title">Upgrade for complete experience</h3>
                            <div className="mt-2">
                                <p className="text-gray-500 dark:text-gray-300 mt-4 mb-2">Sign up to the <a href="https://www.documatic.com" target="_blank" className='text-blue-600 dark:text-blue-400 hover:underline underline-offset-2'>Documatic platform </a> for more features:</p>
                                <ul className='list-disc ml-4 text-sm mb-4 leading-5'>
                                    <li>Connect private and public git repositories</li>
                                    <li>See insights into your code - what it's doing, and how it's doing it</li>
                                    <li>Search your code from Documatic, VSCode, or Slack</li>
                                    <li>Collaborate in teams to solve code problems fast</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-4">
            <h1 className="text-xl mb-2">If you want to index an <span>open source</span> repository,</h1>
            { 
            isLoading ? <LoadingScreen />
            : submitted === undefined ?
            <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg m-auto">
                <div className="bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200" id="modal-title">Fill in the following form</h3>
                            <div className="mt-2 text-center flex-col align-middle justify-center">
                                <p className="text-gray-500 dark:text-gray-300 mt-4 mb-4">We will prioritize the libraries that are requested the most. Please do not spam us.</p>
                                <input value={title} onChange={(e) => setTitle(e.target.value)} className="rounded-lg w-full sm:w-96 mb-2 p-2 border-2 border-gray-600 invalid:border-red-600" placeholder="Name of the library" type="text" />
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className="rounded-lg w-full sm:w-96 mb-2 p-2 border-2 border-gray-600 invalid:border-red-600" placeholder="Your email to notify once the library is added" type="email" />
                                <input value={repoURL} onChange={(e) => setRepoURL(e.target.value)} className="rounded-lg w-full sm:w-96 mb-2 p-2 border-2 border-gray-600 invalid:border-red-600" placeholder="Library URL" type="url" />
                                <button className='rounded-lg w-2/3 px-3 my-4 bg-blue-600 p-2 m-auto text-white' onClick={requestNew}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : submitted ?
            <div className="h-64 bg-gray-300 dark:bg-gray-700 grid place-items-center rounded-lg"> 
                <div className="flex-col justify-items-center align-middle">
                
                <DoneIcon className="h-12 w-12 p-3 m-auto mb-4 bg-green-100 stroke-green-600 rounded-full" />
                <h2 className="text-xl">
                    Your request has been submitted.<br />
                    Please write to us about your feedback at <a href="mailto:info@documatic.com" className="text-blue-300 hover:underline underline-offset-2">info@documatic.com</a> </h2>
                </div>
                
            </div>    
            : 
            <div className="h-64 bg-gray-300 dark:bg-gray-700 grid place-items-center rounded-lg"> 
                <div className="flex-col justify-items-center align-middle">
                
                <CloseIcon className="h-12 w-12 p-3 m-auto mb-4 bg-red-100 stroke-red-600 rounded-full" />
                <h2 className="text-xl">
                    Error occured while submitting.<br />
                    Please try after some time,<br />
                    (OR) write to us at <a href="mailto:info@documatic.com" className="text-blue-300 hover:underline underline-offset-2">info@documatic.com</a> </h2>
                </div>
                
            </div>    
        }
        </div>
    </div>
}