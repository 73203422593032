// export function getViteVar(envVar: string, defaultValue: string | null = null) {
//     const apiHostname = import.meta.env["VITE_" + envVar];
//     return typeof apiHostname === "boolean" ? defaultValue : apiHostname ?? defaultValue;
// }

// import {config} from "dotenv"

// config()

export function getRequiredEnvVar(varName: string) {
    const value = process.env[varName]
    if (value === undefined) {
        throw new Error(`${varName} env var missing`)
    }
    return value
}

export function getRequiredNumericEnvVar(varName: string) {
    const varValue = getRequiredEnvVar(varName)
    const number = parseInt(varValue)
    if (isNaN(number))
        throw new Error(`Invalid numeric value for env var: ${varName}`)

    return number
}