import {useState} from "react";
import {Link, Outlet} from "react-router-dom";
import documaticLogo from '../assets/img/logo.svg';
import documaticSymbol from '../assets/img/symbol.svg';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {ReactComponent as TipsLogo} from '../assets/img/tips.svg'

export function Layout() {
    const [darkToggle, setDarkToggle] = useState(false)
    const rootEl = document.getElementById("root")
    const cookiesLocalStorageKey = (window as any).cookiesLocalStorageKey;
    const [showCookieModal, setShowCookieModal] = useState<boolean>(() => !localStorage.getItem(cookiesLocalStorageKey));
    const links = [
        {"label":"Home","link":"#", left: true, prependIcon: undefined, appendIcon: undefined},
        {"label":"All Libraries","link":"/libraries", left: true, prependIcon: undefined, appendIcon: undefined},
        {"label":"About","link":"/about", left: true, prependIcon: undefined, appendIcon: undefined},
        {"label":"Blog","link":"https://blog.documatic.com/", left: true, prependIcon: undefined, appendIcon: undefined},
        {"label":"How to","link":"/how", left: false, prependIcon: <TipsLogo className="h-5 w-5 mr-1 stroke-black dark:stroke-slate-400 fill-black dark:fill-slate-400"/>, appendIcon: undefined},
        {"label":"Request new codebase","link":"/new", left: false, prependIcon: undefined, appendIcon: undefined},
        {"label":"Frequent","link":"/frequent", left: false, prependIcon: undefined, appendIcon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-black dark:stroke-slate-400" fill="none" viewBox="0 0 24 24" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6"/></svg>},
    ];
    const [showNav, setShowNav] = useState<boolean>(false);

    const setDark = (dark: boolean) => {
        if (darkToggle !== dark) setDarkToggle(dark);
        if (rootEl) {
            if (dark) {
                rootEl.className = "dark";
                localStorage.theme = 'dark'
            } else {
                rootEl.className = "";
                localStorage.theme = 'light'
            }
        }
    }

    setDark(localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))


    return (
        <div
            className="h-screen overflow-auto bg-white text-gray-600 dark:text-gray-400 dark:bg-gray-900 body-font flex flex-col">
            <header className="border-b border-b-slate-200 dark:border-b-slate-700 shadow z-10">
            <div className="container mx-auto flex p-5 flex-row items-center">
    <div className="block lg:hidden">
        <button
            className="flex items-center px-3 py-2 border rounded text-gray-500 dark:text-gray-200 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none mr-4"
            onClick={() => setShowNav(!showNav)}>
            <svg className="fill-dark dark:fill-white h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
        </button>
    </div>
    <a href="https://www.documatic.com/" target="_blank" rel="noreferrer"
       className=" m-auto flex title-font font-medium items-center text-gray-900 dark:text-white mb-0"
       title="Documatic website">
        <img src={documaticLogo} alt="Documatic" className="w-10 h-10 p-2"/>
        <span className="ml-1 text-xl">Documatic</span>
    </a>
    <nav
        className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center text-base w-full">
        <div className={ showNav ? "lg:hidden navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" : "hidden"} onClick={() => setShowNav(false)}></div>
        <div className={showNav ? "fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white dark:bg-gray-600 border-r overflow-y-auto" : "hidden lg:ml-4 lg:flex lg:w-full lg:items-center lg:flex-wrap"}>
        <div className="flex lg:hidden mb-5">
            <img src={documaticSymbol} alt="Documatic" className="h-10 p-2"/>
            <button className="navbar-close ml-auto" onClick={() => setShowNav(false)}>
                <svg className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
        </div>
            {links.map((link, index) =>
                link.link.startsWith("https://") ? <a className={ (showNav ? "h-10 " : "") + "navLink"} href={link.link} target="_blank" rel="noreferrer">{link.label}</a> : <Link className={ (showNav ? " w-full h-10 ": " ") + (link.left ? "navLink" : "float-right inline-flex items-center bg-gray-100 dark:bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-base mt-4 md:mt-0 mr-4") + (link.link==="/how" ? " lg:ml-auto" : "")} to={link.link} onClick={() => {
                    if (link.link === "#") {
                        window.location.href = "/"
                    }
                    setShowNav(false);
                }}>
                    {link.prependIcon}
                    {link.label}
                    {link.appendIcon}
                </Link>
            )}
            <div className={ showNav ? "w-full text-center mt-auto" : "text-sm inline-block"}>
                <div className="ml-4 text-2xl mt-4 md:mt-0">
                    &#9728;
                    <label className="ml-2 mr-1 darkModeSwitch">
                        <input type="checkbox" onClick={() => setDark(!darkToggle)} defaultChecked={darkToggle}/>
                        <span className="darkModeSlider round"></span>
                    </label>
                    &#9790;
                </div>
            </div>
        </div>
    </nav>
</div>
            </header>
            <Outlet context={[darkToggle]}/>
            <ToastContainer position="bottom-center" theme="dark"/>
            {showCookieModal && <div
                className="p-6 fixed bottom-0 z-50 right-0 left-0 bg-primary text-left shadow-2xl dark:shadow-[0_0_30px_rgba(0,0,0,0.3)] transition-opacity flex flex-col gap-2 bg-white dark:bg-gray-900">
                <h1 className="text-2xl">We want your cookies 🍪</h1>
                <p>Cookies allow us to deliver and improve our web content; AskYourCode may use third-party services for
                    this purpose.
                    <a href="https://app.termly.io/document/privacy-policy/c7355b81-8010-42e0-8fcf-82f07ed09c28"
                       className="ml-2 underline">
                        Learn More
                    </a>
                </p>
                <div className="buttons flex gap-4 items-center pt-4">
                    <button
                        className="bg-gray-100 dark:bg-gray-800 border-0 py-2 px-4 hover:bg-gray-200 dark:hover:bg-gray-700 rounded"
                        onClick={() => {
                            const cookiesConsentGranted = (window as any).cookiesConsentGranted
                            if (cookiesConsentGranted) cookiesConsentGranted();
                            setShowCookieModal(false)
                        }}>That's cool
                    </button>
                    <button className="confirm" onClick={() => setShowCookieModal(false)}>No Thanks</button>
                </div>
            </div>}
        </div>
    );
}