/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectVersion
 */
export interface ProjectVersion {
    /**
     * 
     * @type {number}
     * @memberof ProjectVersion
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectVersion
     */
    projectId: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectVersion
     */
    version: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectVersion
     */
    created: Date;
}

export function ProjectVersionFromJSON(json: any): ProjectVersion {
    return ProjectVersionFromJSONTyped(json, false);
}

export function ProjectVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectId': json['projectId'],
        'version': json['version'],
        'created': (new Date(json['created'])),
    };
}

export function ProjectVersionToJSON(value?: ProjectVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectId': value.projectId,
        'version': value.version,
        'created': (value.created.toISOString()),
    };
}

