export function Modal(props: {show: boolean, children: any}) {
    return <div className={`relative z-10 ${props.show ? "visible" : "hidden"}`} aria-labelledby="modal-title" role="dialog" aria-modal="true" >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        {props.children}
      </div>
    </div>
  </div>
  
}