import { getRequiredEnvVar } from "./service/env";
import {
    CodebaseApi,
    CodesearchApi,
    Configuration,
    LoggingApi,
    ProjectApi
} from "./gen/backend";
// import {Loadable, loading, useGet, useThenGet, useWaitThenGet} from "./Load";

const BACKEND_URL = getRequiredEnvVar("REACT_APP_DOCUMATIC_BACKEND_URL");


// function useApiConfiguration(): Configuration {
//     return new Configuration({
//         basePath: BACKEND_URL,
//     });
// }

// function useApi<API>(constructor: (c: Configuration) => API) {
//     return useThenGet(new Configuration({ basePath: BACKEND_URL }), async c => constructor(c));
// }

const configuration = new Configuration({basePath: BACKEND_URL});

export function useProjectApi() {
    return new ProjectApi(configuration);
}

export function useLoggingApi() {
    return new LoggingApi(configuration);
}

export function useCodebaseApi() {
    return new CodebaseApi(configuration);
}

export function useCodesearchApi() {
    return new CodesearchApi(configuration);
}

export enum HttpStatusCode {
    OK = 200,
    UNAUTHENTICATED = 401,
    FAILED_DEPENDENCY = 424,
    CONFLICT = 409,
    FORBIDDEN = 403,
    NOT_FOUND = 404
}

export function errorHandler<T>(statusCode: HttpStatusCode, handler: (message: string) => T) {
    return async (e: unknown): Promise<T> => {
        if (e instanceof Response && e.status === statusCode) {
            const message = await e.text(); // Raw error string is returned by main API
            return handler(message)
        } else {
            throw e
        }
    };
}