/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CodebaseConfig,
    CodebaseConfigFromJSON,
    CodebaseConfigFromJSONTyped,
    CodebaseConfigToJSON,
} from './CodebaseConfig';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    id: number;
    /**
     * 
     * @type {CodebaseConfig}
     * @memberof Project
     */
    codebase?: CodebaseConfig;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    description?: string;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'codebase': !exists(json, 'codebase') ? undefined : CodebaseConfigFromJSON(json['codebase']),
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'codebase': CodebaseConfigToJSON(value.codebase),
        'title': value.title,
        'description': value.description,
    };
}

