import { useState } from "react";
import { CopyBlock, dracula, monoBlue } from "react-code-blocks";
import { useOutletContext } from "react-router-dom";
import { ProjectSnippetVersion } from "../gen/backend";
import { getExtensionFromPath } from "../service/utils";

CustomCopyBlock.defaultProps = {
    lineLimit: 4
}

export function CustomCopyBlock(props: { code: string, func: ProjectSnippetVersion, lineLimit?: number }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const codeSplits = props.code.split("\n");
    const getCode = () => isExpanded ? props.code : codeSplits.slice(0, props.lineLimit).join("\n");
    const [dark] = useOutletContext<boolean[]>();

    const startLine = props.func.startLine;
    return <div className="border border-slate-300 dark:border-slate-800 rounded-b-xl w-full overflow-hidden max-w-[90vw] sm:max-w-none">
        <CopyBlock
            className="rounded-b-none w-full overflow-hidden"
            text={getCode()}
            language={getExtensionFromPath(props.func.filePath)}
            showLineNumbers={true}
            startingLineNumber={props.func.startLine}
            theme={ dark ? dracula : monoBlue}
            codeBlock
        />
        {props.lineLimit && (codeSplits.length > props.lineLimit) && <div className="text-center cursor-pointer text-sm p-2 rounded-b-lg bg-slate-300 dark:bg-slate-800"
            onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? `⬆︎ Minimise (Show ${startLine} - ${startLine + props.lineLimit - 1} only) ⬆` : `⬇︎ Show full snippet (Lines ${startLine + props.lineLimit} - ${startLine + codeSplits.length - 1}) ⬇︎`}
        </div>}
    </div>;
}
