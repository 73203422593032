import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useCodesearchApi, useProjectApi } from "../api";
import { LoadingScreen } from "../components/Loading";
import { FrequentSearchItem, Project } from "../gen/backend";
import { getTimeSince } from "../service/utils";

export function Frequent() {
    const getLibTerm = (libId: number): string => {
        const lib = projects.find(p => p.id === libId)?.title;
        if (typeof lib == "string") return lib[0].toUpperCase() + lib.slice(1);
        else return "";
    }

    const codesearchApi = useCodesearchApi()
    const projectApi = useProjectApi()
    const [projects, setProjects] = useState<Project[]>([])
    const [queries, setQueries] = useState<FrequentSearchItem[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const libraryRef = useRef<HTMLSelectElement>(null);
    const [currentLib, setCurrentLib] = useState<number>(); 

    useEffect( () => {
        setIsLoading(true);
        (async () => {
            const ps = await projectApi.searchProjects({});
            setProjects(ps);
            const qs = await codesearchApi.getFrequentQueries({projectId: currentLib ?? undefined});
            setQueries(qs);
            setIsLoading(false)
        })();
    }, [currentLib]);
    


    return <div className="m-auto"><h2 className="text-xl border-b border-gray-700 mb-3 text-gray-300  ">Most frequent search items</h2>
        <div className="grid place-items-center">
            <div>
                <span className="mr-4">Filter Library</span>
                <select className='rounded-lg sm:rounded-none searchInput w-full sm:w-auto' ref={libraryRef} onChange={(e) => setCurrentLib(Number(e.target.value))} defaultValue={0}>
                    <option value={0} disabled>Library</option>
                    {projects.map(project => <option value={project.id} key={project.id}>{project.title}</option>)}
                </select>
            </div>
        </div>
        {
            isLoading ? <LoadingScreen /> :
        <table className="w-full table-auto border-separate border-spacing-x-7 border-spacing-y-2">
            <thead>
                <tr>
                    <th>Query</th>
                    <th>Library</th>
                    <th>Search count</th>
                    <th>First asked on</th>
                    <th>Last asked on</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>

            {queries.length > 0
                ? queries.map((q, ind) =>
                <tr key={ind} className="border-b border-b-slate-800">
                    <td><Link className="hover:underline underline-offset-2" to={`/?q=${encodeURIComponent(q.query)}&lib=${getLibTerm(q.projectId).toLowerCase()}`}>{q.query} </Link></td>
                    <td>{getLibTerm(q.projectId)}</td>
                    <td>{q.qcount}</td>
                    <td className="text-sm">{getTimeSince(q.firstAsked)} ago</td>
                    <td className="text-sm">{getTimeSince(q.lastAsked)} ago</td>
                    <td><Link className="inline-flex items-center bg-gray-100 dark:bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-700 rounded text-base mt-4 md:mt-0" to={`/?q=${encodeURIComponent(q.query)}&lib=${getLibTerm(q.projectId).toLowerCase()}`}>Search Again</Link></td>
                </tr>
                )
                : <div>No questions yet</div>
            }
            </tbody>
        </table>
        }
        </div>;
}