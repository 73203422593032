/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CodebaseType = {
    Github: 'GITHUB',
    Bitbucket: 'BITBUCKET'
} as const;
export type CodebaseType = typeof CodebaseType[keyof typeof CodebaseType];


export function CodebaseTypeFromJSON(json: any): CodebaseType {
    return CodebaseTypeFromJSONTyped(json, false);
}

export function CodebaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodebaseType {
    return json as CodebaseType;
}

export function CodebaseTypeToJSON(value?: CodebaseType | null): any {
    return value as any;
}

