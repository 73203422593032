/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocsZip
 */
export interface DocsZip {
    /**
     * 
     * @type {string}
     * @memberof DocsZip
     */
    s3url: string;
}

export function DocsZipFromJSON(json: any): DocsZip {
    return DocsZipFromJSONTyped(json, false);
}

export function DocsZipFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocsZip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        's3url': json['s3url'],
    };
}

export function DocsZipToJSON(value?: DocsZip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3url': value.s3url,
    };
}

