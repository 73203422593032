import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import reportWebVitals from './reportWebVitals';
import { About } from './routes/about';
import { Ask } from './routes/ask';
import { Frequent } from './routes/Frequent';
import { Home } from './routes/Home';
import { How } from './routes/How';
import { Libraries } from './routes/Libraries';
import { NewCodebase } from './routes/NewCodebase';
import { Stats } from './routes/Stats';
import './styles/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />} >
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="ask" element={<Ask />} />
          <Route path="frequent" element={<Frequent />} />
          <Route path="how" element={<How />} />
          <Route path="libraries" element={<Libraries />} />
          <Route path="new" element={<NewCodebase />} />
          <Route path="stats" element={<Stats />} />
          <Route
            path="*"
            element={
              <main className='grid place-items-center h-full text-2xl'>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
