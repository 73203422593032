import Lottie from "react-lottie-player"
import animationData from "../assets/animation/loading.json"
// import "../assets/css/loading.scss"

export function LoadingScreen() {
    return <div className="loading-screen grid place-items-center w-full h-full"><Lottie
        className="loading-animation w-1/3"
        animationData={animationData}
        speed={2}
        loop
        play={true}
    />
    </div>;
}