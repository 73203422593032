
export function CurrentQuery(props: { lib: string | null, question: string | null }) {
    return (
      <div>
        {props.lib && props.question ? (
          <h3>
            The <code>library</code> in the query string is &quot;<kbd>{props.lib}</kbd>&quot; and the <code>question</code> is <kbd>{props.question}</kbd>
          </h3>
        ) : (
          <h3>No search yet</h3>
        )}
      </div>
    );
  }
  