/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CodebaseType,
    CodebaseTypeFromJSON,
    CodebaseTypeFromJSONTyped,
    CodebaseTypeToJSON,
} from './CodebaseType';

/**
 * 
 * @export
 * @interface CodebaseConfig
 */
export interface CodebaseConfig {
    /**
     * 
     * @type {CodebaseType}
     * @memberof CodebaseConfig
     */
    type: CodebaseType;
    /**
     * 
     * @type {number}
     * @memberof CodebaseConfig
     */
    id: number;
}

export function CodebaseConfigFromJSON(json: any): CodebaseConfig {
    return CodebaseConfigFromJSONTyped(json, false);
}

export function CodebaseConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodebaseConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': CodebaseTypeFromJSON(json['type']),
        'id': json['id'],
    };
}

export function CodebaseConfigToJSON(value?: CodebaseConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': CodebaseTypeToJSON(value.type),
        'id': value.id,
    };
}

