import { useEffect, useRef, useState } from 'react';
import logo from '../assets/img/logo.svg';
import {ReactComponent as GithubLogo} from '../assets/img/github.svg';
import {ReactComponent as VscodeLogo} from '../assets/img/vscode.svg';
import {ReactComponent as SlackLogo} from '../assets/img/slack.svg';
import {ReactComponent as DoneIcon} from '../assets/img/done.svg';
import shareIcon from '../assets/img/share.svg';
import { useSearchParams } from 'react-router-dom';
import { useCodesearchApi, useProjectApi } from '../api';
import { CodebaseSearchMatch, Project } from '../gen/backend';
import { getExtensionFromPath } from '../service/utils';
import { LoadingScreen } from '../components/Loading';
import { CustomCopyBlock } from '../components/CustomCodeBlock';
import DOMPurify from "dompurify";
import { toast } from 'react-toastify';
import { Modal } from '../components/sub/Modal';

export function Home() {
    const inputRef = useRef<HTMLInputElement>(null);
    const libraryRef = useRef<HTMLSelectElement>(null);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [results, setResults] = useState<CodebaseSearchMatch[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParam = searchParams.get("q");
    const libParam = searchParams.get("lib");
    const [currentLib, setCurrentLib] = useState<string>(libParam ?? "Library"); 
    const projectApi = useProjectApi();
    const [projects, setProjects] = useState<Project[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);

    const searchApi = useCodesearchApi();

    const PLACEHOLDERS = [
        "Where do we connect to databases?",
        "Where do we make REST API requests?",
        "Do we validate payment information?"
    ];
    
    useEffect(() => {
        if (queryParam && inputRef.current?.value !== queryParam) {
            const input = inputRef.current;
            const lib = libraryRef.current;
            if (lib) {
                if (!input && libParam && lib.value !== libParam) {
                    setCurrentLib(libParam);
                }
                else {
                    if (libParam && lib.value !== libParam)
                        lib.value = libParam.toString();
                    if (input) {
                        input.value = queryParam;
                        search()
                    }
                }
            }
        }
    }, [queryParam, inputRef.current, libraryRef.current, projects])

    const search = async () => {
        setIsSearching(true);
        setResults([]);
        const newSearchParams = new URLSearchParams()
        if (inputRef.current?.value) newSearchParams.set("q", inputRef.current?.value)
        if (libraryRef.current?.value) newSearchParams.set("lib", libraryRef.current?.value)
        setSearchParams(newSearchParams);
        try {
            if (inputRef.current?.value) {
                const currentResults = await searchApi.searchFunctions(
                    {
                        q: inputRef.current?.value,
                        projectId: projects.find(i => i.title === currentLib)?.id
                    });
                if (currentResults.length === 0) 
                    toast.info("Your search did not match any results")
                setResults(currentResults)
            }
        } catch (error) {
            console.log(error)
            console.log(JSON.stringify(error))
            toast.error(`An error has occurred: ${error}`)
        }
        setIsSearching(false);
    }

    const shareResult = async (result: CodebaseSearchMatch) => {
        await navigator.share({
            text: `${result.snippet.summary}`,
            url: document.location.href,
            title: `How does it work - ${inputRef.current?.value}`
        })
    }

    async function exportSearchResults() {
        const searchString = inputRef.current?.value;
        const projectSelect = currentLib;

        const mdContent = [
            "# Code Search Results - Documatic",
            "> Powered by Documatic  ",
            `Search query - ${DOMPurify.sanitize(searchString!)}  `,
            `Selected project - ${projectSelect ?? "None"}  `,
            `Exported at - ${new Date().toString()} (${new Date().getTime()})  `,
            "", "",
            "## Search results",
            results.map(result =>
                    [
                        `### ${result.codebase.url} - ${result.snippet.filePath}`,
                        "*Distance* - " + result.distance.toFixed(2) + "/1.00  ",
                        "*Summary* - " + (result.snippet.summary ?? "No summary") + "  ",
                        "```" + getExtensionFromPath(result.snippet.filePath),
                        DOMPurify.sanitize(result.code.replaceAll("`", "\\`")),
                        "```"
                    ].join("\n")).join("\n"),
            "### Happy Coding,  ",
            "Made with &#10084; by Team Ask Your Code\n\n",
            "![Documatic Logo](https://www.documatic.com/svg/logo.svg)  ",
            "info@documatic.com"
        ];
        const element = document.createElement("a");
        const file = new Blob([mdContent.join("\n")], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = `Documatic_search_results__${searchString}__v${new Date().getTime()}.md`;
        document.body.appendChild(element);
        element.click();
    }

    
    useEffect(() => {
        setIsLoading(true)
        projectApi.searchProjects({})
            .then(tmpProjects => {
                setIsLoading(false)
                setProjects(tmpProjects)
            })
    }, []);
    
    return isLoading ? <LoadingScreen /> :
    <div className={`mt-4 ${(isSearching || results.length > 0) ? "flex isSearching flex-col flex-wrap": "-mt-16 grid place-items-center flex-grow overflow-auto"}`}>
        <div className='flex flex-col isSearching:flex-row gap-4 pb-4 isSearching:mb-4 isSearching:border-b border-b-gray-700 isSearching:max-w-3xl m-auto'>
            
            <img src={logo} className="hidden sm:block w-36 h-36 isSearching:w-24 isSearching:h-24 m-auto isSearching:mr-0 isSearching:align-self-end" alt="Documatic" />
            <h1 className='text-2xl text-center isSearching:hidden'>How does <span className="text-sky-500 transition-all">{(currentLib !== "Library" && projects.find(i => i.title === currentLib)) ? projects.find(i => i.title === currentLib)?.title : "it"}</span> work?</h1>
            <div className='flex flex-grow isSearching:flex-row flex-wrap'>
                <div className="p-2 sm:p-0 flex flex-grow flex-wrap gap-1 sm:gap-0 sm:flex-nowrap text-md isSearching:basis-full">

                    <input 
                    placeholder={PLACEHOLDERS[0]}
                    ref={inputRef}
                    onKeyDown={e => {
                        if (e.code === "Enter") {
                            e.preventDefault()
                            search()
                        }
                    }}
                    className='rounded-lg sm:rounded-r-none w-full sm:w-96 isSearching:min-w-max p-2 searchInput isSearching:w-full' />
                    <select className='rounded-lg sm:rounded-none py-2 sm:py-0 searchInput w-full sm:w-auto' ref={libraryRef} onChange={(e) => setCurrentLib(e.target.value)} defaultValue={currentLib}>
                        <option value={0} disabled>Library</option>
                        {projects.map(project => <option value={project.title} key={project.id}>{project.title}</option>)}
                    </select>
                    <button className='rounded-lg sm:rounded-l-none w-full sm:w-auto px-3 py-2 sm:py-0 searchInput grid place-items-center' onClick={search}>
                        { isSearching ?
                        <div className="border border-blue-600 border-t-blue-300 rounded-full w-4 h-4 animate-spin"></div>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 fill-transparent stroke-black dark:stroke-slate-400" viewBox="0 0 24 24" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                        }
                    </button>
                </div>
                {
                    (isSearching || results.length > 0) &&
                    <div className='m-auto mt-3 flex gap-2'>
                    <button className='button' onClick={exportSearchResults}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 stroke-white fill-transparent" viewBox="0 0 24 24" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                        Export to Markdown
                    </button>
                    <button className='button bg-green-600 ' onClick={() => setShowSignUpModal(true)}>
                        <GithubLogo className='h-5 w-5 stroke-transparent fill-black dark:fill-white inline-block mr-3' />
                        Search your private codebase
                    </button>
                    </div>
                    }
            </div>
        </div>
        {isSearching && <div className='hidden isSearching:flex'>
            
        <div className='hidden flex-grow isSearching:flex flex-col '>
            { Array(4).fill(1).map( (i, j)=> 
            <div key={i+j} className="border border-blue-200 dark:border-blue-900 shadow rounded-md p-4 mb-4 max-w-3xl w-full mx-auto">
                <div className=" flex flex-wrap">
                    <div className="animate-pulse flex-1 space-y-3">
                        <div className="flex">

                            <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-5 w-5"></div>
                            <div className="h-5 ml-4 w-1/12 bg-slate-300 dark:bg-slate-700 rounded"></div>
                            <div className="h-5 ml-4 w-1/4 bg-slate-300 dark:bg-slate-700 rounded"></div>
                            <div className="ml-auto mr-0 flex">

                                <div className="rounded-full ml-2 border border-slate-300 dark:border-slate-700 h-5 w-5 p-1 bg-slate-300 dark:bg-slate-700">
                                    <div className="rounded-full m-0 w-full h-full bg-slate-200 dark:bg-slate-900"></div>
                                </div>
                                <div className="rounded-full ml-2 border border-slate-300 dark:border-slate-700 h-5 w-5 p-1 bg-slate-300 dark:bg-slate-700">
                                    <div className="rounded-full m-0 w-full h-full bg-slate-200 dark:bg-slate-900"></div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row flex-wrap gap-2'>
                            <div className="h-2 w-full bg-slate-300 dark:bg-slate-700 rounded"></div>
                            <div className="h-2 w-3/4 bg-slate-300 dark:bg-slate-700 rounded"></div>
                        </div>
                        <div className="space-y-3 border border-slate-300 dark:border-slate-700 p-2">
                            {["1/2","8/12","5/12","1/2"].map((j, ind) => 
                                <div key={j+ind} className={`flex w-`+j}>
                                    <div className="h-2 w-4 mr-3 rounded bg-slate-300 dark:bg-slate-700"></div>
                                    <div className="h-2 w-full rounded bg-slate-300 dark:bg-slate-700"></div>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-4">
                            <div className="h-4 w-12 rounded bg-slate-300 dark:bg-slate-700"></div>
                            <div className="h-4 w-16 rounded bg-slate-300 dark:bg-slate-700"></div>
                        </div>
                    </div>
                </div>
            </div>
        )}
                </div>
        </div>
        }
        {(results.length > 0) && <div className="flex flex-row flex-wrap flex-grow gap-4 max-w-3xl m-auto">
            {results.map((result, ind) =>
            <div key={ind} className="border border-blue-200 dark:border-blue-900 shadow rounded-md p-4 mb-4 max-w-3xl mx-auto w-full break-all">
                <div className="flex flex-wrap w-full">
                    <div className="flex-1 space-y-3 w-full">
                        <div className="flex flex-col sm:flex-row w-full">
                            {/* <img src={githubLogo} alt="GitHub" className='h-5 w-5 mr-2' /> */}
                            <a target="_blank" rel="noreferrer" className='text-sm hover:underline underline-offset-2 hover:text-blue-400' href={result.codebase.url}>
                            <GithubLogo className='h-5 w-5 stroke-transparent fill-black dark:fill-white inline-block mr-3' />
                                <span className='font-bold tracking-wider'>{result.codebase.url.split("/").slice(3,5).join("/")}</span></a>
                            <a target="_blank" rel="noreferrer" className='text-sm pl-1 hover:underline underline-offset-2 hover:text-blue-400' href={`${result.codebase.url}/blob/${result.version.version}/${result.snippet.filePath}#L${result.snippet.startLine}:L${result.snippet.endLine}`}>/{result.snippet.filePath}</a>
                            <div className="m-auto pt-2 sm:pt-0 sm:mr-0 flex relative">
                                <button data-flow="up" data-tooltip="Add a slack integration" className="externalIconsOnSearch" onClick={() => setShowSignUpModal(true)}><SlackLogo /></button>
                                <a target="_blank" rel="noreferrer" data-flow="up" data-tooltip="Open in gitHub.dev" className="externalIconsOnSearch" href={`${result.codebase.url.replace("github.com","github.dev")}/blob/${result.version.version}/${result.snippet.filePath}#L${result.snippet.startLine}:L${result.snippet.endLine}`}><VscodeLogo /></a>
                                { navigator.share !== undefined && <div data-flow="up" data-tooltip="Share" className="externalIconsOnSearch" onClick={() => shareResult(result)}><img src={shareIcon} alt="Share" /></div>}
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row flex-wrap gap-2">{result.snippet.summary} </div>
                        <CustomCopyBlock code={result.code} func={result.snippet} />
                        <div className="flex gap-4">
                            <div className="pl-1 rounded text-sm bg-slate-300 dark:bg-slate-800">Language <span className='bg-slate-200 dark:bg-slate-900 text-slate-900 dark:text-slate-400 inline-block my-0.5 mr-0.5 px-2 py-0.5 rounded-r'>{getExtensionFromPath(result.snippet.filePath)}</span></div>
                            { result.snippet.owner && 
                                <div className="pl-1 rounded text-sm bg-slate-300 dark:bg-slate-800">Author <span className='bg-slate-200 dark:bg-slate-900 text-slate-900 dark:text-slate-400 inline-block my-0.5 mr-0.5 px-2 py-0.5 rounded-r'>{result.snippet.owner?.name}&lt;{result.snippet.owner?.email}&gt;</span></div>
                            }
                        </div>
                    </div>
                </div>
            </div> 
            )}
        </div>
        }
        <Modal show={showSignUpModal} >
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DoneIcon className="m-auto mt-2 mb-4 h-12 w-12 p-3 rounded-full stroke-green-500 bg-green-100 border-none" />
                <h3 className="text-center text-lg font-medium leading-6 text-gray-900" id="modal-title">Upgrade for a complete experience</h3>
                <div className="mt-2">
                    <p className="text-gray-500 mt-4 mb-2">Sign up to the <a href="https://app.documatic.com" target="_blank" rel='noreferrer' className='text-blue-600 hover:underline underline-offset-2'>Documatic platform </a> for more features:</p>
                    <ul className='list-disc ml-4 text-sm mb-4 leading-5'>
                        <li>Connect private and public git repositories</li>
                        <li>See insights into your code - what it's doing, and how it's doing it</li>
                        <li>Search your code from Documatic, VSCode, or Slack</li>
                        <li>Collaborate in teams to solve code problems fast</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 text-center">
            <button type="button" className="button inline-block border hover:bg-gray-200 text-black bg-transparent sm:w-auto sm:text-sm" onClick={() => setShowSignUpModal(false)}>Close</button>
            <a type="button" rel='noreferrer' className="button inline-block ml-2 bg-blue-600 hover:bg-blue-700 sm:w-auto sm:text-sm" href="https://www.documatic.com" target="_blank">Sign up</a>
          </div>
        </div>
        </Modal>
        
    </div>
}