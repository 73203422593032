/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CodebaseType,
    CodebaseTypeFromJSON,
    CodebaseTypeFromJSONTyped,
    CodebaseTypeToJSON,
} from './CodebaseType';

/**
 * 
 * @export
 * @interface Codebase
 */
export interface Codebase {
    /**
     * 
     * @type {number}
     * @memberof Codebase
     */
    id: number;
    /**
     * 
     * @type {CodebaseType}
     * @memberof Codebase
     */
    type: CodebaseType;
    /**
     * 
     * @type {string}
     * @memberof Codebase
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Codebase
     */
    url: string;
}

export function CodebaseFromJSON(json: any): Codebase {
    return CodebaseFromJSONTyped(json, false);
}

export function CodebaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Codebase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': CodebaseTypeFromJSON(json['type']),
        'title': json['title'],
        'url': json['url'],
    };
}

export function CodebaseToJSON(value?: Codebase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CodebaseTypeToJSON(value.type),
        'title': value.title,
        'url': value.url,
    };
}

