/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocsZip,
    DocsZipFromJSON,
    DocsZipToJSON,
    FunctionWithCode,
    FunctionWithCodeFromJSON,
    FunctionWithCodeToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectOverview,
    ProjectOverviewFromJSON,
    ProjectOverviewToJSON,
    RequestNewCodebaseRequest,
    RequestNewCodebaseRequestFromJSON,
    RequestNewCodebaseRequestToJSON,
} from '../models';

export interface GetProjectRequest {
    id: number;
}

export interface GetProjectDocsRequest {
    id: number;
}

export interface GetProjectOverviewRequest {
    projectId: number;
}

export interface GetProjectSnippetRequest {
    snippetId: number;
}

export interface RequestNewCodebaseOperationRequest {
    requestNewCodebaseRequest: RequestNewCodebaseRequest;
}

export interface SearchProjectsRequest {
    offset?: number;
    limit?: number;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Returns the project associated with the ID in the path
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Returns the project associated with the ID in the path
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Project> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the project docs from s3 as a zip file
     */
    async getProjectDocsRaw(requestParameters: GetProjectDocsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<DocsZip>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProjectDocs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{id}/docs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocsZipFromJSON(jsonValue));
    }

    /**
     * Returns the project docs from s3 as a zip file
     */
    async getProjectDocs(requestParameters: GetProjectDocsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<DocsZip> {
        const response = await this.getProjectDocsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns an overview for this project
     */
    async getProjectOverviewRaw(requestParameters: GetProjectOverviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ProjectOverview>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectOverview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/overview`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectOverviewFromJSON(jsonValue));
    }

    /**
     * Returns an overview for this project
     */
    async getProjectOverview(requestParameters: GetProjectOverviewRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ProjectOverview> {
        const response = await this.getProjectOverviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a function from a project by ID
     */
    async getProjectSnippetRaw(requestParameters: GetProjectSnippetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<FunctionWithCode>> {
        if (requestParameters.snippetId === null || requestParameters.snippetId === undefined) {
            throw new runtime.RequiredError('snippetId','Required parameter requestParameters.snippetId was null or undefined when calling getProjectSnippet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/snippet/{snippetId}`.replace(`{${"snippetId"}}`, encodeURIComponent(String(requestParameters.snippetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FunctionWithCodeFromJSON(jsonValue));
    }

    /**
     * Returns a function from a project by ID
     */
    async getProjectSnippet(requestParameters: GetProjectSnippetRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<FunctionWithCode> {
        const response = await this.getProjectSnippetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request a new codebase
     */
    async requestNewCodebaseRaw(requestParameters: RequestNewCodebaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestNewCodebaseRequest === null || requestParameters.requestNewCodebaseRequest === undefined) {
            throw new runtime.RequiredError('requestNewCodebaseRequest','Required parameter requestParameters.requestNewCodebaseRequest was null or undefined when calling requestNewCodebase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestNewCodebaseRequestToJSON(requestParameters.requestNewCodebaseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new codebase
     */
    async requestNewCodebase(requestParameters: RequestNewCodebaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.requestNewCodebaseRaw(requestParameters, initOverrides);
    }

    /**
     * Returns all the list of projects.
     */
    async searchProjectsRaw(requestParameters: SearchProjectsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * Returns all the list of projects.
     */
    async searchProjects(requestParameters: SearchProjectsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<Project>> {
        const response = await this.searchProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
