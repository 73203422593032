/* tslint:disable */
/* eslint-disable */
/**
 * How does XYZ work API
 * The backend API for managing the public - how does XYZ work website
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchHistoryItem,
    SearchHistoryItemFromJSON,
    SearchHistoryItemFromJSONTyped,
    SearchHistoryItemToJSON,
} from './SearchHistoryItem';

/**
 * 
 * @export
 * @interface GetSearchHistory200Response
 */
export interface GetSearchHistory200Response {
    /**
     * 
     * @type {number}
     * @memberof GetSearchHistory200Response
     */
    totalItems: number;
    /**
     * 
     * @type {number}
     * @memberof GetSearchHistory200Response
     */
    totalPages: number;
    /**
     * 
     * @type {Array<SearchHistoryItem>}
     * @memberof GetSearchHistory200Response
     */
    items: Array<SearchHistoryItem>;
}

export function GetSearchHistory200ResponseFromJSON(json: any): GetSearchHistory200Response {
    return GetSearchHistory200ResponseFromJSONTyped(json, false);
}

export function GetSearchHistory200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSearchHistory200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItems': json['totalItems'],
        'totalPages': json['totalPages'],
        'items': ((json['items'] as Array<any>).map(SearchHistoryItemFromJSON)),
    };
}

export function GetSearchHistory200ResponseToJSON(value?: GetSearchHistory200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItems': value.totalItems,
        'totalPages': value.totalPages,
        'items': ((value.items as Array<any>).map(SearchHistoryItemToJSON)),
    };
}

