import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CurrentQuery } from "../components/CurrentQuery";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  
  export function Ask() {
    let query = useQuery();
    
    return <CurrentQuery lib={query.get("lib")} question={query.get("question")} />;
  }